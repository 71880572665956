/* SortByPrice.css */
.sort-by-section {
    display: flex;
    align-items: center;
    margin-bottom: 0.3125rem;
    max-width: 100%;
    flex-wrap: wrap;
  }
  
  .sort-by-label {
    font-size: 0.9375rem;
    margin-right: 0.625rem;
    display: flex;
    align-items: center;
  }
  
  .dropdown select {
    padding: 0.5rem 0.75rem;
    font-size: 0.9375rem;
    cursor: pointer;
    text-align: center;
  }

  .dropdown1 select {
    padding: 0.5rem 0.75rem;
    font-size: 0.9375rem;
    cursor: pointer;
    text-align: center;
    margin-right: 2.5rem;
  }

  .column-smallscreen {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    .sort-by-section {
      margin-bottom: 0.3125rem;
    }
    
    .sort-by-label {
      font-size: 0.70rem;
      margin-right: 0.25rem;
      margin-bottom: 0.1rem;
    }
    
    .dropdown select {
      padding: 0.1rem 0.3rem;
      font-size: 0.6rem; 
    }
  
    .dropdown1 select {
      padding: 0.1rem 0.3rem;
      font-size: 0.6rem;
      margin-right: 1rem;
    }

    .column-smallscreen {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (min-width: 3000px) {
    .sort-by-label {
      font-size: 1.2rem;
    }
    
    .dropdown select {
      font-size: 1.2rem; 
    }
  
    .dropdown1 select {
      font-size: 1.2rem; 
    }
  }
