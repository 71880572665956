.descuentos {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
}
.subscribe {
    font-size: 1.3rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.subscribe p {
    margin-top:  0rem;
}

.subscribe table {
    margin: 0 auto;
    border-collapse: collapse;
}

.subscribe th, .subscribe td {
    border: 1px solid black;
    padding: 10px;
    text-align: center;
}

.descuento-activo {
    color: green;
    font-weight: bold;
}

@media (max-width: 767px) {
    .descuentos {
        margin: 3.5rem 1rem 20rem 1rem;
    }

    .subscribe p {
        font-size: 1rem;
    }

    .subscribe th, .subscribe td {
        border: 0.6px solid black;
        padding: 10px;
        text-align: center;
        font-size: 1rem;
    }

}
