/* SortByBrand.css */
.brand-section {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
}

.section-title {
  font-size: 0.9375rem;
  margin-bottom: 1.25rem;
}

.section-subtitle {
  font-size: 0.9375rem;
  margin-bottom: 0.625rem;
  border-bottom: 0.125rem solid #ccc;
  padding-bottom: 0.3125rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-subtitle p {
  margin-bottom: 0;
}

.checkbox-column {
  display: flex;
  flex-direction: column;
}

.catalogs-column {
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
  margin-top: 0.4375rem;
  margin-bottom: 0rem;
  gap: 0.3125rem;
  font-size: 0.9375rem;
}

.brand-name {
  margin-bottom: 0.625rem;
}

.row-colors {
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
}

.button-colors {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 2.19rem;
  height: 2.19rem;
  border-radius: 50%;
  border: 0.03125rem solid #ddd;
  margin-bottom: 0.03125rem;
  position: relative;

}

.column-colors {
  display: flex;
  flex-direction: column;
  margin-right: 0.9rem;
  margin-bottom: 0.5rem;
  align-content: center;
  font-size: 0.75rem;
  width: 3.15rem;
  height: 4.375rem;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.checkmark-colors {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.125rem;
}

@media (max-width: 767px) {  
  .section-title {
    font-size: 0.7rem;
    margin-top: 0;
    margin-bottom: 0.7rem;
  }
  
  .section-subtitle {
    font-size: 0.65rem;
    border-bottom: 0.06rem solid #ccc;
    margin-top: 0.8rem;
  }
  
  .catalogs-column {
    margin-top: 0.15rem;
    margin-left: 0.4rem;
    gap: 0rem;
    font-size: 0.6rem;
  }
  
  .brand-name {
    font-size: 0.65rem;
  }

  .button-colors {
    width: 1.3rem;
    height: 1.3rem;
    border: 0.03125rem solid #ddd;
    margin-bottom: 0.03125rem;
  }
  
  .column-colors {
    margin-right: 0.2rem;
    margin-bottom: 0rem;
    align-content: center;
    font-size: 0.65rem;
    width: 2.5rem;
    height: 3rem;
  }
  
  .checkmark-colors {
    font-size: 1rem;
  }

}

@media (min-width: 3000px) {
  .section-title {
    font-size: 1.3rem;
  }
  
  .section-subtitle {
    font-size: 1.3rem;
  }
  
  .catalogs-column {
    font-size: 1.3rem;
  }
  
  .brand-name {
    font-size: 1.3rem;
  }

  .button-colors {
    width: 3rem;
    height: 3rem;
    border: 0.06rem solid #ddd;
    margin-bottom: 0.25rem;
  }
  
  .column-colors {
    margin-right: 1rem;
    margin-bottom: 0.8rem;
    font-size: 1rem;
    width: 4.15rem;
    height: 5.375rem;
  }
  
  .checkmark-colors {
    font-size: 1.3rem;
  }
}
