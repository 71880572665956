/* Header.css */
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 2rem; /* REM =  Root EM -> unit of measurement that is relative to the font size of the root element 
                        of a webpage, set in the html element */
  border-bottom: 1px dotted #333333;
  max-width: 100%; /* will never be wider than the viewport's width */
  /* flex-wrap: wrap; allow itenms to wrap to a new line on smaller screen */
}

.display-name {
  font-size: 0.8rem;
  margin-bottom: 0.6rem;
}

.column-header {
  display: flex;
  flex-direction: column;
}

.row-profile {
  background: #e8e7e7;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;
  padding-right: 1.4rem;
  cursor: pointer;
}

img {
  height: 3.75rem;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

nav li {
  margin-right: 1.25rem;
}

nav li:last-child {
  margin-right: 0;
}

a {
  text-decoration: none;
  color: #333;
  font-size: 1.125rem;
}

.cart-profile-icons {
  display: flex;
  align-items: center;
}

.cart-icon-margin {
  margin-right: 0.625rem;
}

.profile-icon-margin {
  margin-right: 0.3rem;
}

nav li.active {
  border-bottom: 0.125rem solid #333;
  color: #333;
  font-weight: bold;
}

/* CSS media query ->  apply different techniques to an element based on the characteristics of the device or viewport 
(responsive layouts)

Small screen: 320px-767px
Medium screens: 768-1023px
Large screens: 1024px+ */

@media (max-width: 767px) {
  header {
    padding: 0.5rem 0.7rem;
  }

  .display-name {
    font-size: 0.5rem;
    margin-bottom: 0.4rem;
  }

  .row-profile {
    padding: 0.05rem;
    padding-right: 0.5rem;
  }

  img {
    height: 2.5rem;
  }
  
  nav li {
    margin-right: 0.5rem;
  }
  
  a {
    font-size: 0.8rem;
  }
  
  .cart-profile-icons {
    display: flex;
    align-items: center;
  }
  
  .cart-icon-margin {
    margin-right: 0.3rem;
  }

  .profile-icon-margin {
    margin-right: 0.05rem;
  }
  
  
  nav li.active {
    border-bottom: 0.07rem solid #333;
  }
}

@media (min-width: 3000px) {
  nav li {
    margin-right: 1.8rem;
  }
  
  a {
    font-size: 1.4rem;
  }

  .display-name {
    font-size: 1.2rem;
  }

  .cart-icon-margin {
    margin-right: 0.5rem;
  }
  
  .profile-icon-margin {
    margin-right: 0.5rem;
  }
}
