/* CartIcon.css */
.cart-item-count {
  position: absolute;
  top: -0.30rem; /* top position to overlap -> negative increase up, positive increase down */
  right: -0.55rem; /* right position to overlap -> negative increase, left positive inceease right*/
  width: 1.3rem;
  height: 1.3rem;
  background-color:#0077B6;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 1.25rem;
  font-size: 0.875rem;
  font-weight: bold;
  z-index: 1;
}

.cart-item-count-3 {
  position: absolute;
  top: -0.2rem; /* top position to overlap -> negative increase up, positive increase down */
  right: -0.55rem; /* right position to overlap -> negative increase, left positive inceease right*/
  width: 1.4rem;
  height: 1.4rem;
  background-color:#0077B6;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 1.25rem;
  font-size: 0.65rem;
  font-weight: bold;
  z-index: 1;
}

.cart-icon-display {
  position: relative; /* able to locate the item wherever */
  display: inline-block; 
}

.cart-icon-display img {
  width: 2.45rem;
  height: 2.45rem;
  cursor: pointer;
}

@media (max-width: 767px) {
  .cart-icon-display img {
    width: 1.7rem; 
    height: 1.7rem;
  }

  .cart-item-count {
    top: -0.40rem;
    right: -0.5rem;
    width: 1.1rem;
    height: 1.1rem;
    line-height: 1rem;
    font-size: 0.5rem;
  }

  .cart-item-count-3 {
    top: -0.2rem;
    right: -0.6rem;
    width: 1.2rem;
    height: 1.2rem;
    line-height: 1rem;
    font-size: 0.6rem;
  }
}

@media (min-width: 3000px) {
  .cart-icon-display img {
    width: 3rem; 
    height: 3rem;
  }

  .cart-item-count {
    top: -0.4rem;
    right: -0.7rem;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 1rem;
  }
}
