.informacion {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-left: 5rem;
}

.user-info p {
    font-size: 1.4rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 0rem;
}

.list-addresses {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.list-addresses p {
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
}

@media (max-width: 767px) {
    .informacion {
        margin: 3.5rem 1rem 15rem 1rem;
    }

    .user-info p {
        font-size: 1rem;
    }

    .list-addresses p {
        font-size: 1rem;
    }

}
