/* CheckoutStep1.css */
.checkoutstep1-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70vh; 
    gap: 3.125rem;
    padding-bottom: 6.25rem;
}

@media (max-width: 767px) {
    .checkoutstep1-content {
        flex-direction: column;
        height: 75vh; 
        gap: 0rem;
    }
}


@media (min-width: 3000px) {
    .checkoutstep1-content {
        font-size: 1.2rem;
        gap: 3.125rem;
        padding-bottom: 15rem;
    }
}