/* OrderPlacedDetails.css */
.order-placed-details-container {
    max-width: 56.25rem;
    margin: 0 auto;
    margin-bottom: 6.25rem;
    padding: 1.25rem 1.25rem 5rem 1.25rem;
    text-align: center;
  }
  
  .order-placed-title {
    font-size: 2.5rem;
    margin-bottom: 3.75rem;
    margin-top: 3.125rem;
  }
  
  .order-placed-message {
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
  }
  
  .payment-heading {
    font-size: 1.5rem;
    margin-bottom: 1.875rem;
  }

  .order-id {
    font-size: 1.125rem;
    color: #333333;
    margin-bottom: 3.125rem;
  }

  .payment-info {
    padding: 0.625rem;
    border-radius: 0.625rem;
    margin-top: 1.25rem;
    background-color: #CCCCCC;
  }

  .informacion-pago-monto {
    font-size: 1.25rem;
  }

  .informacion-pago-details {
    font-size: 1.3rem;
    margin: 0.3125rem;
    margin-top: 0.7rem;
  }

  .informacion-pago-recibo {
    font-weight: bold;
    font-size: 1.125rem;
  }
  
  .resumen-de-orden {
    margin-top: 3.125rem;
  }

  .subtotal-table2 {
    width: 100%;
    border-collapse: collapse;
    border: 0.0625rem solid #ccc;
  }

  .subtotal-table2 th,
  .subtotal-table2 td {
    border: 0.0625rem solid #ccc;
    padding: 0.5rem;
    font-size: 0.9375rem;
  }

  .subtotal-table2 th {
    background-color: #e6e3e3;
    text-align: center;
    color: black;
    padding: 0.625rem;
  }

  .row-table2 {
    background-color: #e6e3e3;
    font-weight: bold;
    color: black;
    padding: 0.3125rem;
  }

  .resumen-de-orden-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.25rem;
    flex-direction: column;
  }
  

  .facturacion-orderplaced {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-right: 1.25rem;
  }
  
  .billing-detail {
    margin: 0.1rem;
    font-size: 0.9375rem;
  }

  .subtotal-column2 {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.625rem;
  }

  .shipping-orderplaced {
    text-align: left;
    display: flex;
    flex-direction: column;
  }
  
  
  .both-addresses {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    gap: 0.625rem;
    padding: 0.5rem;
    border-radius: 0.625rem;
    width: 80%;
  }

  @media (max-width: 767px) {
    .order-placed-details-container {
      margin: 2rem 1rem;
      max-width: 100%;
      padding: 0rem;
    }

    .order-placed-title {
      font-size: 1.3rem;
      margin-bottom: 2rem;
      margin-top: 3rem;
    }

    .order-placed-message {
      font-size: 0.8rem;
      margin-bottom: 1.5rem;
    }

    .order-id {
      font-size: 0.9rem;
      margin-bottom: 2rem;
    }

    .payment-heading {
      font-size: 0.9rem;
      margin-bottom: 1.2rem;
    }

    .informacion-pago-monto {
      font-size: 1rem;
    }

    .informacion-pago-details {
      font-size: 0.8rem; 
      margin: 0.3125rem;
    }

    .informacion-pago-recibo {
      font-weight: bold;
      font-size: 0.9rem;
    }

    .resumen-de-orden {
      margin-top: 2.5rem;
    }

    .payment-info {
      padding: 0.625rem;
      max-width: 100%;
      box-sizing: border-box;
    }
    
    .subtotal-column2 {
      width: 100%;
      margin-bottom: 0.3rem;
    }

    .subtotal-table2 {
      border: 0.03rem solid #ccc;
    }

    .subtotal-table2 th,
    .subtotal-table2 td {
      border: 0.03rem solid #ccc;
      padding: 0.4rem;
      font-size: 0.6rem;
    }

    .both-addresses {
      display: flex-start;
      flex-direction: column;
      margin-top: 1.25rem;
      gap: 0.7rem;
      width: 90%;
      box-sizing: border-box;
    }

    .facturacion-orderplaced p {
      font-size: 0.7rem;
    }

    .shipping-orderplaced p {
      font-size: 0.7rem;
    }
  }

  @media (min-width: 3000px) {
    .order-placed-message {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
    
    .payment-heading {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
  
    .order-id {
      font-size: 1.6rem;
      margin-bottom: 3.125rem;
    }
  
    .payment-info {
      font-size: 1.4rem;
    }
  
    .informacion-pago-monto {
      font-size: 1.4rem;
    }
  
    .informacion-pago-details {
      font-size: 1.4rem;
    }
  
    .informacion-pago-recibo {
      font-size: 1.4rem;
    }
  
    .subtotal-table2 th,
    .subtotal-table2 td {
      font-size: 1.3em;
    }
  
    .billing-detail {
      font-size: 1.2rem;
    }

    .facturacion-orderplaced, .shipping-orderplaced {
      font-size: 1.2rem;
    }
  }
