.checkout-step2-container {
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
    margin: 2.5rem 5rem;
    padding-bottom: 5rem;
    flex-wrap: wrap;
  }
  
  .form-container,
  .facturacion-column {
    flex: 1;
  }
  
  .facturacion-column {
    margin-bottom: 18.75rem;
  }

  .form-column {
    display: flex;
    flex-direction: column;
  }
  
  .form-column label {
    margin-bottom: 0.3125rem;
  }
  
  .form-column input {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
    font-size: 0.75rem;
  }

  .titulo-checkout2 {
    border-bottom: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
    padding: 0.625rem;
    margin-bottom: 1.25rem; 
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titulo-checkout2 p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
  }

  .checbox-checkout2 {
    margin-bottom: 1.5rem;
  }

  .subtotal-column {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
  }

  .subtotal-table {
    width: 100%;
    border-collapse: collapse;
    border: 0.0625rem solid #ccc;
  }
  
  .subtotal-table th,
  .subtotal-table td {
    border: 0.0625rem solid #ccc;
    padding: 0.5rem;
    font-size: 0.8rem;
  }
  
  .subtotal-table th {
    background-color:#CCCCCC;
    text-align: center;
    color: black;
  }

  .row-table {
    background-color: #CCCCCC;
    font-weight: bold;
    color: black;
  }

  .checkout2-button {
    margin: 2.5rem 10rem;
    padding: 1.1rem;
    background-color:#CCCCCC;
    font-size: 0.9375rem;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 0.7rem;
    cursor: pointer;
  }
  
  .notes-input::placeholder {
    vertical-align: top;
  }

  .notes-input {
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
    font-family: inherit;
    font-size: 0.875rem;
    resize: none;
  }

  .payment-checkout-column label {
    margin-right: 1.6rem;
  }

  .payment-checkout-column select {
    padding: 0.625rem;
    font-size: 1rem;
    text-align: center;
  }

  .juridico-personal {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.2rem;
    font-size: 1rem;
  }

  .dropdown2 select {
    padding: 0.5rem 0.75rem;
    font-size: 0.9375rem;
    cursor: pointer;
    text-align: center;
    margin-right: 2.5rem;
    border: none;
    border-bottom: 1px solid #ccc;
  }

  @media (max-width: 767px) {
    .checkout-step2-container {
      flex-direction: column;
      gap: 5rem;
      margin: 2rem 1rem;
    }

    .dropdown2 select {
      padding: 0.1rem 0.3rem;
      font-size: 0.6rem;
      width: 100%;
      box-sizing: border-box; 
      margin-bottom: 0.5rem;
    }

    .facturacion-column {
      margin-bottom: 0rem;
    }

    .titulo-checkout2 {
      border-bottom: 0.03rem solid #ccc;
      padding: 0.3rem;
      margin-bottom: 0.7rem;
      font-size: 0.9rem;
    }

    .titulo-checkout2  p {
     font-size: 0.9rem;
    }
    
    .form-column label {
      font-size: 0.7rem;
    }

    .form-column input {
      border: 0.03rem solid #ccc;
      font-size: 0.70rem;
      padding: 0.5rem;
    }

    .checbox-checkout2 {
      padding-top: 0.5rem;
      font-size: 0.8rem;
      margin-bottom: 0.8rem;
    }

    .juridico-personal {
      margin-bottom: 1rem;
      font-size: 0.8rem;
    }

    .notes-input {
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      border: 0.03rem solid #ccc;
      font-size: 0.7rem;
    }
    
    .payment-checkout-column label {
      font-size: 0.7rem;
      margin-right: 0.6rem;
    }
  
    .payment-checkout-column select {
      padding: 0.4rem;
      font-size: 0.7rem;
    }

    .subtotal-column {
      padding: 1rem;
    }
    
    .subtotal-table th,
    .subtotal-table td {
      border: 0.03rem solid #ccc;
      padding: 0.5rem;
      font-size: 0.55rem;
    }
    
    .checkout2-button {
      margin: 3rem auto;
      font-size: 0.9rem;
      padding: 0.8rem 1.2rem;
      font-size: 0.7rem;
    }

    .subtotal-table {
      border: 0.03rem solid #ccc;
    }
  }

  @media (min-width: 3000px) {
    .checkout-step2-container {
      margin: 5rem 8rem;
    }

    .subtotal-column {
      flex: 0.3;
    }
    .checkout-step2-container {
      padding-bottom: 40rem;
      gap: 4rem;
    }
 
    .checbox-checkout2 {
      font-size: 1.2rem;
    }

    .dropdown2 select {
      font-size: 1.2rem;
    }
    
    .form-column input {
      font-size: 1.2rem;
      margin-bottom: 0.8rem;
    }

    .form-column {
      font-size: 1.2rem;
    }

    .juridico-personal {
      margin-bottom: 1.5rem;
      font-size: 1.2rem;
    }
    
    .subtotal-table th,
    .subtotal-table td {
      font-size: 1rem;
    }
  
    .checkout2-button {
      padding: 1.2rem;
      margin-top: 5rem;
      font-size: 1.1rem;
      margin: 3rem auto;
    }
  
    .notes-input {
      font-size: 1.2rem;
    }
  
    .payment-checkout-column select {
      font-size: 1.2rem;
    }

    .payment-checkout-column {
      font-size: 1.2rem;
    }
  }
