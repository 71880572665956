.forgotpass-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
    margin: 5rem 12.5rem;
    padding-bottom: 12.5rem;
  }

.forgotpass-column {
    flex: 1;
    padding: 1.25rem;
    text-align: center;
    background-color: #F5F5F5;
    width: 100%;
  }

  .forgotpass-col {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center;
  }
  
  .forgotpass-col input {
    margin: 1rem 0rem;
    padding: 1rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
    font-size: 0.9375rem;
    width: 50%;
    align-items: center;
  }
  
  .forgotpass-button {
    padding: 0.8rem 1rem;
    font-size: 0.9375rem;
    color: black;
    border: none;
    border-radius: 0.3125rem;
    cursor: pointer;
    margin: 0.9375rem 3.125rem;
  }

  @media (max-width: 767px) {
    .forgotpass-container {
      margin: 2rem 1.5rem;
    }
  
    .forgotpass-container h2{
      font-size: 1rem;
      margin-bottom: 0rem;
    }

    .forgotpass-container p{
      font-size: 0.8rem;
      margin: 1.5rem 0;
    }

    .forgotpass-col input {
      margin-bottom: 1.25rem;
      padding: 0.8rem;
      border: 0.03rem solid #ccc;
      font-size: 0.8rem;
      width: 90%;
      margin: 0.6rem 0rem;
    }

    .forgotpass-button {
      padding: 0.7rem 1rem;
      font-size: 0.8rem;
    }
  }

  @media (min-width: 3000px) {
    .forgotpass-container {
      margin: 5rem 35rem;
      padding-bottom: 20rem;
    }

    .forgotpass-container h2{
      font-size: 1.8rem;
    }

    .forgotpass-container p{
      font-size: 1.4rem;
    }
  
    .forgotpass-col input {
      font-size: 1.3rem;
    }
    
    .forgotpass-button {
      padding: 1rem 1.2rem;
      font-size: 1.2rem;
    }
  }