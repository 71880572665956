/* DynamicPage.css */
.main-content {
  margin: 3.125rem 6.875rem 0 6.875rem;
}

.tab-name {
  background: linear-gradient(to right, #666666, #CCCCCC);
  color: white;
  padding: 6.25rem 2.5rem 2.5rem 3.125rem;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}

.sort-section {
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.columns {
  display: flex;
  gap: 3.125rem;
  max-width: 100%;
  overflow: hidden;
}

.brands-column {
  flex: 0.5; /* takes half the space available, and then products column 2.5 times. 
  how items are distributed withion a flex componenet -> 
  determine how much space should take up relatie to other columns within the same container*/
  max-width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
}

.products-column {
  flex: 2.5;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .main-content {
    margin: 1.5rem 1rem 0 1rem; /* top right bottom left  2.8rem 1.5rem; */
  }
  
  .tab-name {
    padding: 4.75rem 1rem 1rem 1.525rem;
    font-size: 0.9rem;
  }

  .columns {
    gap: 1rem;
  }

  .brands-column {
    flex: 0.75;
  }
}

@media (min-width: 3000px) {
  .tab-name {
    padding: 8rem 2.5rem 2.5rem 3.125rem;
    font-size: 1.8rem;
    margin-bottom: 1.8rem;
  }
}
