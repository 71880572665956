/* ProductDetails.css */
.product-details-content {
  margin: 3.75rem 6.875rem;
  padding-bottom: 6.25rem;
}

.carrito-quantity-column {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  color: #706e6e;
}

  .cart-image-prod {
    width: 1.1rem;
    height: 1.1rem;
  }
  
  .columns-horizontal {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4rem;
  }
  
  .image-container {
    flex: 1;
    width: 31.25rem;
    height: 31.25rem;
    overflow: hidden;

  }
  
  .product-image {
    display: flex;
    width: 100%;
    height: 31.25rem;
    object-fit: cover;
  }
  
  .columns-vertical {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
  }
  
  .product-title-col {
    margin: 0;
    font-size: 2.375rem;
    font-weight: 450;
  }

  .product-name-col {
    margin-top: 0.625rem;
    font-size: 1.5625rem;
    font-weight: 450;
  }

  .product-price-col {
    font-size: 1.56rem;
    margin-top: 2.81rem;
    font-weight: 700;
  }

  .available-col {
    font-size: 1rem;
    margin-top: 0.625rem;
    color: #0077B6;
  }

  .add-to-cart-button {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    padding: 0.625rem 1.25rem;
    border: none;
    border-radius: 0.3125rem;
    cursor: pointer;
  }

  .error-adding-cart {
    margin-bottom: 7.5rem;
    width: 100%;
    text-align: center;
  }
  
  .add-to-cart-button:not([disabled]):hover {
    background-color:  #CCCCCC;
  }
  
  .quantity-controls {
    display: flex;
    align-items: center;
    margin-top: 3.125rem;
  }

  .quantity-controls button {
    padding: 0.3125rem 0.625rem;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 0.3125rem;
    cursor: pointer;
    font-size: 0.9375rem;
  }

  .quantity-controls button:not([disabled]):hover {
    background-color:  #CCCCCC;
  }

  .quantity-label {
    font-size: 1.125rem;
    font-weight: normal;
    margin-right: 0.625rem;
  }

  .quantity-display {
    font-size: 1.125rem;
    font-weight: normal;
    margin: 0 0.625rem;
  }

  .images-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }

  .thumbnail-images {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.625rem;
    width: 31.25rem;
    flex-wrap: wrap;
  }

  .thumbnail {
    width: 5rem;
    height: 5rem;
    margin-left: 0;
    margin-right: 0.6rem;
    margin-top: 0.5rem;
    cursor: pointer;
  }

  .thumbnail.selected {
    border: 0.0625rem solid  #333333;
  }

  .horizontal-line {
    margin-top: 2.5rem;
    margin-bottom: 0.3125rem;
    width: 100%;
    border-top: 0.0625rem solid #ccc;
    border-bottom: 0.0625rem solid #ccc;
  }

  .horizontal-line p {
    font-size: 0.9375rem;
  }
 
  .medida-div {
    margin-top: 0.9375rem;
  }

  .medida-div label {
    margin-right: 7px;
  }
  
  .custom-select {
    padding: 0.25rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
    font-size: 0.9375rem;
  }

  .info-rectangle {
    background: #f0f0f0;
    color: black;
    margin-top: 1.875rem;
    padding: 0.625rem;
    width: 100%;
    font-size: 1.125rem;
    font-weight: 200;
    text-align: center;
  }

  .product-price-discount {
    display: flex;
    flex-direction: column;
  }

  .product-price-col {
    font-size: 1.56rem;
    margin-top: 2.81rem;
    font-weight: 700;
  }

  .product-price-col-small {
    font-size: 1.2rem;
    margin-top: 0.7rem;
    margin-left: auto;
  }

  .product-discount-options {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-top: 1rem;
  }

  .product-discount-option {
    font-size: 0.8rem;
    color: gray;
    margin: 0rem;
  }

  .product-discount-options-active {
    font-size: 0.8rem;
    font-weight: bold;
    color: red;
    margin: 0rem;
  }

  @media (max-width: 767px) {
    .product-details-content {
      margin: 1.5rem 1rem 0 1rem;
    }

    .product-discount-option {
      font-size: 0.7rem;
    }

    .product-discount-options-active {
      font-size: 0.7rem;
      font-weight: bold;
      color: red;
      margin: 0rem;
    }

    .cart-image-prod {
      width: 1rem;
      height: 1rem;
    }
    
    .columns-horizontal {
      flex-direction: column;
      align-items: center;
      gap: 2.5rem;
      width: 100%;
    }
    
    .image-container {
      width: 100%;
      height: 50vh;
      object-fit: contain;
      flex: 1;
    }

    .columns-vertical {
      width: 100%;
      margin-top: 0.5rem;
    }
    
    .product-image {
      display: flex;
      width: 100%;
      height: 20rem;
      object-fit: cover;
    }
    
    .product-title-col {
      font-size: 1.7rem;
      font-weight: 450;
      text-align: left;
      margin: 0;
    }

    .product-name-col {
      margin-top: 0.625rem;
      font-size: 1.5625rem;
      font-weight: 450;
    }

    .medida-div label {
      font-size: 0.8rem;
    }

    .available-col {
      font-size: 0.8rem;
      margin-top: 0.625rem;
    }

    .carrito-quantity {
      font-size: 0.7rem;
      margin-top: 0.4rem;
    }

    .add-to-cart-button {
      margin: 4rem auto 1rem auto;
      width: 80%;
      text-align: center;
      padding: 0.625rem 1.25rem;
      font-size: 0.7rem;
    }
    
    .quantity-controls {
      margin-top: 2.5rem;
    }

    .quantity-controls button {
      padding: 0.3rem 0.5rem;
      font-size: 0.9rem;
    }

    .quantity-label {
      font-size: 1rem;
    }

    .quantity-display {
      font-size: 1rem;
      margin: 0 0.625rem;
    }

    .images-thumbnail {
      display: flex;
      width: 100%; 
    }

    .thumbnail-images {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      margin-top: 0.3rem;
      width: 100%;
    }

    .thumbnail {
      width: 4rem;
      height: 4rem;
      margin-right: 0.3125rem;
      margin-left: 0;
      cursor: pointer;
    }

    .thumbnail.selected {
      border: 0.05rem solid  #333333;
    }

    .horizontal-line {
      margin-top: 1.5rem;
      margin-bottom: 0.3125rem;
      border-top: 0.04rem solid #ccc;
      border-bottom: 0.04rem solid #ccc;
    }

    .horizontal-line p {
      font-size: 0.8rem;
    }
    
    .custom-select {
      padding: 0.1rem;
      border: 0.0625rem solid #ccc;
      border-radius: 0.25rem;
      font-size: 0.8rem;
    }

    .info-rectangle {
      margin-top: 2rem;
      padding: 0.625rem;
      max-width: 100%;
      font-size: 0.9rem;
      box-sizing: border-box; 
      font-weight: 200;
    }
  }

  @media (min-width: 3000px) {
    
    .error-adding-cart {
      width: 60%;
      margin-bottom: 7.5rem;
    }

    .carrito-quantity-column {
      gap: 0.5rem;
      font-size: 1rem;
      margin-top: 0.5rem;
    }

      .cart-image-prod {
        width: 1.2rem;
        height: 1.2rem;
      }

    .product-details-content {
      margin: 5rem 8rem;
      padding-bottom: 10rem;
    }
    
      .image-container {
        width: 50rem;
        height: 50rem;
      }
      
      .product-image {
        height: 50rem;
      }
      
      .product-title-col {
        font-size: 3rem;
      }

      .product-price-col {
        font-size: 1.8rem;
        margin-top:3rem;
      }
    
      .available-col {
        font-size: 1.2rem;
        margin-top: 0.8rem;
      }
    
      .carrito-quantity {
        font-size: 1rem;
        margin-top: 0.5rem;
      }

      .add-to-cart-button {
        margin-top: 5rem;
        margin-bottom: 2rem;
        width: 100%;
        font-size: 1rem;
        padding: 0.8rem 1.25rem;
      }
      
      .quantity-controls {
        margin-top: 5rem;
      }
    
      .quantity-controls button {
        padding: 0.5rem 0.8rem;
      }
    
      .quantity-label {
        font-size: 1.2rem;
      }
    
      .quantity-display {
        font-size: 1.2rem;
      }

      .thumbnail {
        width: 6rem;
        height: 6rem;
      }
    
      .horizontal-line {
        margin-top: 5rem;
        margin-bottom: 0.5rem;
        width: 60%;
      }

      .horizontal-line p{
        font-size: 1.2rem;
      }

      .medida-div{
        font-size: 1.2rem;
      }

      .custom-select {
        font-size: 1.2rem;
      }
    
      .info-rectangle {
        margin-top: 5rem;
        padding: 0.9rem;
        font-size: 1.2rem;
      }
  }
