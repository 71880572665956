.login-signup {
    display: flex;
    justify-content: center;
    margin: 5rem 5rem;
    padding-bottom: 9.375rem;
  }
  
  .content-loginsignup {
    display: flex;
    gap: 1.25rem;
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
  }
  
  .login-column,
  .signup-column {
    flex: 1;
    padding: 1.25rem;
    text-align: center;
  }
  
  .login-column {
    background-color: #e4e4e4;
    color: #333333;
    width: 100%;
    padding: 1.25rem;
    text-align: center;
  }
  
  .signup-column {
    background-color: #F5F5F5;
    border: 0.0625rem solid #E0E0E0;
    color: #333333;
    width: 100%;
    padding: 1.25rem;
    text-align: center;
  }

  .login-button {
    padding: 0.625rem;
    font-size: 0.9375rem;
    color: black;
    border: none;
    border-radius: 0.3125rem;
    cursor: pointer;
    margin: 0.9375rem 3.125rem;
  }

  .login-button:hover {
    background-color: #ccc;
  }

  .google-button {
    padding: 0.625rem 0.3125rem;
    font-size: 0.9375rem;
    color: black;
    border: none;
    border-radius: 0.3125rem;
    cursor: pointer;
  }

  .google-button:hover {
    background-color: #ccc;
  }
  
  .form-column-login {
    display: flex;
    flex-direction: column;
  }
  
  .form-column-login input {
    margin-bottom: 0.625rem;
    padding: 0.75rem;
    border: 0.0625rem solid #ccc;
    border-radius: 0.25rem;
    font-size: 0.9375rem;
  }

  .form-column-login label {
    margin-bottom: 0.5rem;
    padding: 0;
    font-size: 0.8rem;
  }

  .error-message {
    color: red;
  }

  .forgot-password {
    cursor: pointer;
    padding-top: 0.9375rem;
    text-decoration: underline;
  }

  .forgot-password:hover {
    color:black;
    font-weight: bold;
  }

  @media (max-width: 767px) {
    .login-signup {
      margin: 2rem 1.5rem;
      padding-bottom: 2rem;
    }
    
    .content-loginsignup {
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 2rem;
    }
    
    .login-column,
    .signup-column {
      box-sizing: border-box; /* ensures the padding and border are included in the defined width*/
    }
    
    .login-column h2 {
      font-size: 1rem;
      margin-top: 0rem;
    }

    .form-column-login input {
      padding: 0.5rem;
      border: 0.03rem solid #ccc;
      font-size: 0.8rem;
    }

    .form-column-login label {
      margin-bottom: 0.3rem;
      padding: 0;
      font-size: 0.65rem;
    }

    .login-button {
      padding: 0.6rem 0.3rem;
      font-size: 0.75rem;
      background-color: #ffffff;
    }

    .google-button {
      padding: 0.6rem 0.3rem;
      font-size: 0.75rem;
      margin: 0rem 1.5rem;
    }

    .forgot-password {
      padding-top: 0.3rem;
      font-size: 0.8rem;
      margin-bottom: 0rem;
    }

    .signup-column h2 {
      font-size: 1rem;
      margin-top: 0rem;
    }
  
    .error-message {
      font-size: 0.8rem;
    }
  }

  @media (min-width: 3000px) {
    .signup-column h2 {
      font-size: 1.8rem;
      margin-bottom: 2.5rem;
    }

    .login-column h2 {
      font-size: 1.8rem;
      margin-bottom: 2.5rem;
    }

    .login-signup {
      padding-bottom: 50rem;
      margin: 15rem 8rem;
    }
    
    .content-loginsignup {
      width: 100%;
      max-width: 90rem;
    }
    
    .login-column {
      padding: 2rem;
    }
    
    .signup-column {
      padding: 2rem;
    }
  
    .login-button {
      padding: 0.8rem 0.5rem;
      font-size: 1.3rem;
      margin: 1.5rem 5rem;
    }
  
    .google-button {
      padding: 0.8rem 0.5rem;
      font-size: 1.3rem;
    }
    
    .form-column-login input {
      padding: 1rem;
      font-size: 1.3rem;
    }
  
    .error-message {
      color: red;
      font-size: 1.4rem;
    }
  
    .forgot-password {
      font-size: 1.3rem;
      padding-top: 1.3rem;
    }
  }
