.suscripcion {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
}


.suscription-info p {
    font-size: 1.4rem;
    text-align: center;
    margin-top: 0rem;
}

.no-suscription p {
    font-size: 1.3rem;
    margin-top: 0rem;
}

.no-suscription {
    text-align: center;
    align-items: center;
    justify-content: center;
}

.suscribirse-button {
    margin-top: 1.5rem;
    padding: 1.1rem;
    font-size: 1.3rem;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 0.7rem;
    cursor: pointer;
}

.suscribirse-button:hover {
    background-color:#CCCCCC;
    cursor: pointer;
}

@media (max-width: 767px) {
    .suscripcion {
        margin: 3.5rem 1rem 20rem 1rem;
    }

    .suscription-info p {
        font-size: 1rem;
    }

    .no-suscription p {
        font-size: 1rem;
    }

    .suscribirse-button {
        margin-top: 1.5rem;
        padding: 1rem;
        font-size: 0.9rem;
        border-radius: 0.7rem;
    }
}
