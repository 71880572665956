.balance {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: 10rem;
}

.balance-title {
    font-size: 1.4rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.balance-title p {
    margin-top: 0rem;
}

@media (max-width: 767px) {
    .balance {
        margin: 3.5rem 1rem 30rem 1rem;
    }

    .balance-title p {
        font-size: 1rem;
    }
}
