.notification-container {
    position: fixed;
    top: 7.5rem;
    right: 1.875rem;
    background-color: #fff;
    padding: 0.625rem;
    display: flex;
    z-index: 9999; /*Elements with a higher z-index value will be displayed in front of elements with lower z-index values. */
    border-bottom: 0.0625rem dotted #333333;
    border-right: 0.0625rem dotted #333333;
    border-left: 0.0625rem dotted #333333;
    flex-direction: column;
  }
  
  .notification-close-button {
    background: none;
    border: none;
    font-size: 1.5625rem;
    cursor: pointer;
  }

  .go-to-cart-button {
    margin: 0.625rem;
    padding: 0.75rem 1.25rem;
    font-size: 0.9375rem;
    color: black;
    border: none;
    border-radius: 1.25rem;
    cursor: pointer;
    width: 7.5rem;
    margin: 0.625rem auto;
    display: block; 
  }

  .go-to-cart-button:hover {
    border: 0.0625rem solid #0077B6;
 } 

  .notification-overlay {
    position: fixed;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9998;
  }
  
  .añadido-carrito {
    margin-bottom: 1.5625rem;
    margin-top: 0.3125rem;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  .product-info-notification {
    text-align: left;
    margin-right: 3.75rem;
    margin-bottom: 0.9375rem;
  }

  .check-added {
    display: flex;
    align-items: center;
  }

  .check-icon {
    margin-right: 0.5rem;
  }


    @media (max-width: 767px) {
      .notification-container {
        top: 5.3rem;
        right: 1rem;
      }
      
      .notification-close-button {
        font-size: 1.3rem;
      }
    
      .go-to-cart-button {
        margin-bottom: 0rem;
        padding: 0.6rem 0.8rem;
        font-size: 0.8rem;
        width: 80%;
        margin: 0.5rem auto;
      }
    
      .go-to-cart-button:hover {
        border: 0.03rem solid #0077B6;
     } 
      
      .añadido-carrito {
        margin-bottom: 0.4rem;
        margin-top: 0rem;
        font-size: 0.8rem;
      }
    
      .product-info-notification {
        text-align: left;
        margin-right: 0.5rem;
        text-align: left;
        margin-bottom: 0.4rem;
        font-size: 0.8rem;
      }
    }
  

@media (min-width: 3000px) {
  .notification-close-button {
    font-size: 1.7rem;
  }

  .notification-container {
    top: 8.3rem;
    right: 1.875rem;
  }

  .go-to-cart-button {
    margin: 0.625rem auto;
    padding: 0.75rem 1.25rem;
    font-size: 1.2rem;
    border-radius: 1.25rem;
    width: 10rem;
  }

  .product-info-notification {
    text-align: left;
    margin-right: 6rem;
    margin-bottom: 0.9375rem;
    font-size: 1.2rem;
  }

  .añadido-carrito {
    font-size: 1.2rem;
  }
}
