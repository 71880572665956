.orders-list {
    margin-top: 4rem;
}

.order-id {
    font-size: 1.1rem;
    font-weight: 600;
}

.total-price {
    margin-left: 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
}

.product-name-table {
    cursor: pointer;
}

.product-name-table:hover {
    text-decoration: underline;
}

.item-pedidos {
    width: 100%;
    flex: 4.5;
    max-width: 100%;
    border-collapse: separate; 
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .item-pedidos th {
    text-align: center;
    padding: 0.125rem;
    font-size: 0.9375rem;
    border-bottom: 0.0625rem solid #ddd;
  }

  .item-pedidos td {
    padding: 0.125rem;
    font-size: 0.9375rem;
    text-align: center;
    border-bottom: 0.0625rem solid #ddd;
    border-bottom: none;
  }

  .item-pedidos tr:last-child td {
    border-bottom: 0.0625rem solid #ddd;
  }

  @media (max-width: 767px) {
  .orders-list {
    margin-top: 3.5rem;
  }

  .order-id {
    font-size: 1rem;
  }

  .total-price {
    margin-left: 0rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }

  .item-pedidos th {
    padding: 0.05rem;
    font-size: 0.7rem;
    border-bottom: 0.03rem solid #ddd;
  }

  .item-pedidos td {
    padding: 0.05rem;
    font-size: 0.7rem;
    border-bottom: 0.03rem solid #ddd;
  }

  .item-pedidos tr:last-child td {
    border-bottom: 0.03rem solid #ddd;
  }

  .direccion-de-envio {
    font-size: 0.8rem;
  }

  .order-summary {
    margin-bottom: 1.5rem;
  }

  .order-summary ul {
    font-size: 0.75rem;
    padding-left: 0rem;
  }

  .order-summary li {
    margin-left: 1.2rem;
  }

  .list-orders {
    padding-left: 1rem;
  }
}
