.pedidos-title{
  margin-top: 2.5rem;
  font-size: 1.125rem;
  font-weight: 700;
  border-bottom: 0.0625rem solid #333333; 
}

.order-summary {
  margin-bottom: 1.25rem;
}

.no-order-summary {
  margin-top: 5rem;
  margin-bottom: 6.25rem;
  text-align: center;
}

.sign-out-button {
  padding: 1rem;
  font-size: 0.9375rem;
  color: black;
  border: none;
  border-radius: 1.25rem;
  cursor: pointer;
  width: 15%;
  margin-top: 3rem;
}

.sign-out-button:hover {
  background-color: #CCCCCC;
  cursor: pointer;
}

.main-content-profile {
  margin: 3.125rem 6.875rem 18rem 6.875rem;
  padding-bottom: 6.875rem;
}

.orders-list {
  padding-bottom: 3.125rem;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 50px;
  display: row;
  border-bottom: 0.0625rem solid #ccc;
  font-size: 1.1rem;
  margin-top: 2rem;
}

.tab {
  cursor: pointer;
}

.active-tab {
  font-weight: bold;
  cursor: pointer;
}

.profile-account {
  margin-top: 3rem;
  font-size: 1rem;
  color: gray;
}

.profile-name {
  font-size: 3.5rem;
  font-weight: 650;
  margin-bottom: 4rem;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}


@media (max-width: 767px) {

  .profile-account {
    font-size: 0.8rem;
  }

  .profile-name {
    font-size: 2rem;
    font-weight: 650;
    margin-bottom: 3rem;
  }

  .tabs {
    gap: 20px;
    flex-wrap: wrap;
    border-bottom: 0.03rem solid #ccc;
    border-top: 0.03rem solid #ccc;
    font-size: 0.8rem;
    margin-top: 2rem;
  }

  .main-content-profile {
    margin: 1.5rem 1rem 0 1rem; 
  }

  .tab-name {
    padding: 4.75rem 1rem 1rem 1.525rem;
    font-size: 0.9rem;
  }

  .pedidos-title{
    margin-top: 2rem;
    font-size: 0.9rem;
    font-weight: 700;
    border-bottom: 0.03rem solid #333333; 
  }
  
  .no-order-summary {
    font-size: 0.8rem;
    margin-top: 1.5rem;
  }

  .sign-out-button {
    padding: 0.8rem 0.8rem;
    font-size: 0.8rem;
    width: 40%;
  }

  .order-summary span {
    font-size: 0.8rem;
  }

  .order-summary li {
    font-size: 0.7rem;
  }
}
  
  
@media (min-width: 3000px) {
  .main-content-profile {
   padding-bottom: 50rem;
  }

  .pedidos-title{
    font-size: 1.4rem;
  }
  
  .sign-out-button {
    font-size: 1.3rem;
    width: 8%;
  }
  
  .order-summary span {
    font-size: 1.2rem;
  }

  .order-summary li {
    font-size: 1.2rem;
  }
}
