/* ProfileIcon.css */
  .profile-icon-display {
    position: relative;
    display: inline-block;
   display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  .profile-icon-class {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    margin-right: 0.2rem;
  }

  .custom-icon-class {
    width: 1.9rem;
    height: 1.9rem;
    cursor: pointer;
    margin-right: 0.2rem;  
   }
  
  @media (max-width: 767px) {
    .custom-icon-class {
      width: 1.25rem;
      height: 1.25rem;
    }

    .profile-icon-class {
      width: 1rem;
      height: 1rem;
      margin-right: 0.15rem;
    }
  }

  @media (min-width: 3000px) {

    .custom-icon-class {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
