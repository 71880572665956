.contact-us-container {
  background-color: #DDDDDD;
  padding: 1.875rem;
  text-align: center;
  margin-top: 0rem;
}
  
.contact-us-container h2 {
  font-size: 1.25rem;
  margin-bottom: 0.625rem;
}
  
.contact-us-container ul {
  list-style-type: none;
  padding: 0;
}
  
.contact-us-container li {
  margin-bottom: 0.3125rem;
  font-size: 1rem;
}
  
.whatsapp-link {
  font-size: 1.0625rem;
  display: block;
  text-decoration: underline;
  margin-bottom: 0.3125rem;
}

.whatsapp-link:hover {
  color:#000000;
  font-weight: bold;
}

.whatsapp-link-li {
  display: block;
  flex-direction: column;
  margin-top: 0.9375rem;
}
  
.float {
  position: fixed;
  width: 3.75rem;
  height: 3.75rem;
  bottom: 1.875rem;
  left: 2.5rem;
  background-color: #25d366;
  color: #FFF;
  border-radius: 3.125rem;
  text-align: center;
  font-size: 1.875rem;
  box-shadow: 0.3125rem 0.3125rem 0.3125rem #d6d6d6;
  z-index: 100;
}
  
.my-float{
  margin-top: 1rem;
}

.float1 {
  position: fixed;
  width: 1.375rem;
  height: 1.375rem;
  bottom: 0.9375rem;
  right: 4.6875rem;
  color: #000000;
  font-size: 0.875rem;
  z-index: 100;
  cursor: pointer;
  background-color: #E8E8E8;
    /* background-color:  #d8d7d7; */
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.float1:hover {
    /* background-color:  #ccc; */
  background-color: #d1d1d1;
}
  
.float2 {
  position: fixed;
  width: 1.375rem;
  height: 1.375rem;
  bottom: 0.9375rem;
  right: 3.125rem;
  color: #000000;
  font-size: 0.875rem;
  z-index: 100;
  cursor: pointer;
  background-color:#E8E8E8;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.float2:hover {
    background-color: #d1d1d1;
}

@media (max-width: 767px) {
  .contact-us-container {
    padding: 1.875rem;
  }
    
  .contact-us-container h2 {
    font-size: 0.9rem;
  }
    
  .contact-us-container li {
    font-size: 0.8rem;
  }
    
  .whatsapp-link {
    font-size: 0.7rem;
    margin-bottom: 0.3125rem;
  }
    
  .float {
    width: 2.625rem;
    height: 2.625rem;
    bottom: 1.2775rem;
    left: 1.75rem;
    border-radius: 1.3125rem;
    font-size: 1.3125rem;
    box-shadow: 0.22rem 0.22rem 0.22rem #d6d6d6;
  }
    
  .my-float{
    margin-top: 0.7rem;
  }
  
  .float1 {
    width: 1rem;
    height: 1rem;
    bottom: 0.65625rem;
    right: 3.3rem;
    font-size: 0.6125rem;
  }
    
  .float2 {
    width: 1rem;
    height: 1rem;
    bottom: 0.65625rem;
    right: 2rem;
    font-size: 0.6125rem;
  }
}
  
@media (min-width: 3000px) {
  .contact-us-container h2 {
    font-size: 1.2rem;
  }
    
  .contact-us-container li {
    font-size: 1.2rem;
  }

  .whatsapp-link {
    font-size: 1.2rem;
    padding: 0.2rem;
  }

  .float1 {
    width: 0.1rem;
    height: 0.1rem;
    right: 4.3rem;
    font-size: 0.8rem;
    margin-bottom: 0.7rem;
  }

  .float2 {
    width: 0.1rem;
    height: 0.1rem;
    right: 2rem;
    font-size: 0.8rem;
    margin-bottom: 0.7rem;
  }
}

  
