    .main-page-home {
      display: flex;
      flex-direction: column;
      background-color: #d6d6d6;
      min-height: 100vh;
      padding-bottom: 10rem;
    }  
    
    .banner {
      position: relative;
      text-align: center;
    }

    .banner-image {
      top: 0;
      width: 100%;
      height: 100%;
    }

    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }

    .banner-text {
      position: absolute;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 3rem;
      background-color: rgba(221, 221, 221, 0.6);
      padding: 0rem 2rem;
      font-family: "Alegreya", serif;
      font-weight: 500;
      font-style: normal;
    }

    .row-tabs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 7rem;
      max-width: 100%;
      margin: 6rem 2rem;
    }

    .column-tab {
      display: flex;
      flex-direction: column;
    }
    
    .circular-img {
      border-radius: 50%;
      width: 300px;
      height: 300px;
      object-fit: cover;
      cursor: pointer;
    }
    
    .circular-img:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }
    

    .tab-img-name {
      text-align: center;
      font-family: "Alegreya", serif;
      font-weight: 500;
      font-style: normal;
      font-size: 1.4rem;
      cursor: pointer;
    }

    .tab-img-name:hover {
      font-weight: bold;
    }
  
  .brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.875rem;
    width: 100%;
    border-top: 0.0625rem solid #ccc;
    border-bottom: 0.0625rem solid #ccc;
    background-color: white;
  }
  
  .brands img {
    height: 5rem;
    object-fit: cover;
    cursor: pointer;
    margin: 1.875rem;
  }

    @media (max-width: 767px) {

      .main-page-home {
        min-height: 100vh;
        padding-bottom: 8rem;
      }

      .desktop {
        display: none;
      }
  
      .mobile {
        display: block;
      }
  
      .banner-text {
        top: 43%;
        font-size: 1.3rem;
        padding: 0.1rem 0.5rem;
      }
  
      .row-tabs {
        gap: 2rem;
        max-width: 100%;
        margin: 3rem 1rem;
      }
      
      .circular-img {
        width: 150px;
        height: 150px;
      }
      
      .circular-img:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      }
      
      .tab-img-name {
        font-size: 0.8rem;
      }
      
      .brands img {
        height: 3rem;
        margin: 1rem;
      } 
      
    }

    @media (min-width: 3000px) {

      .main-page-home {
        min-height: 100vh;
        padding-bottom: 15rem;
      }  
  
      .banner-text {
        top: 43%;
        font-size: 5rem;
        padding: 0rem 1rem;
      }
  
      .row-tabs {
        gap: 8rem;
        margin: 7rem 1rem;
      }
      
      .circular-img {
        width: 400px;
        height: 400px;
      }
      
      .circular-img:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      }
      
      .tab-img-name {
        font-size: 2rem;
      }
      
      .brands img {
        height: 8rem;
        margin: 5rem;
      } 
    }

          
