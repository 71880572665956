.product-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  gap: 1.875rem;
  justify-items: center;
  margin-bottom: 4.375rem;
  margin-top: 1.5625rem;
}

.ningun-producto-descuento {
  font-size: 1.2rem;
  color: red;
  text-align: center;
  padding-top: 3rem;
}

.product-section-content {
  margin-bottom: 5rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination span {
  margin: 0 1.25rem;
}

.pagination button {
  padding: 0.3125rem 0.9375rem;
  font-size: 0.9375rem;
  border: none;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.pagination button:not([disabled]):hover {
  color:#333333;
  font-weight: bold;
}

.product-card {
  width: 100%;
  max-width: none;
  padding: 0.0625rem;
  box-sizing: border-box;
}

.product-name {
  font-size: 1rem;
  cursor: pointer;
  text-align: left;
  margin: 0.3125rem 0;
}

.product-name-number {
  font-size: 1rem;
  text-align: left;
  margin: 0.125rem 0;
}

.product-price {
  font-size: 0.875rem;
  text-align: right;
  font-weight: 700;
  margin-top: 0.7rem;
  margin-bottom: 0.3125rem;
}

.product-image-container {
  height: 13rem;
  width: 100%;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
}

@media (max-width: 767px) {  
  .product-section {
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
    gap: 0.8rem;
    margin-bottom: 3rem;
    margin-top: 0.6rem;
  }

  .ningun-producto-descuento {
    font-size: 0.6rem;
  }
  
  .pagination span {
    margin: 0 1.2rem;
    font-size: 0.6rem;
  }
  
  .pagination button {
    padding: 0.2rem 0.4rem;
    font-size: 0.6rem;
  }

  .product-card {
    padding: 0.0625rem;
  }
  
  .product-name {
    font-size: 0.65rem;
    margin-bottom: 0.1rem;
  }
  
  .product-name-number {
    font-size: 0.60rem;
    margin: 0;
  }
  
  .product-price {
    font-size: 0.55rem;
    margin-top: 0.2rem;
    margin-bottom: 0;
  }
  
  .product-image-container {
    height: 7rem;
    width: 100%;
    object-fit: contain;
  }
}

@media (min-width: 3000px) {
  .product-section {
    grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    gap: 2rem;
    margin-bottom: 10rem;
  }

  .ningun-producto-descuento {
    font-size: 1.3rem;
  }
  
  .product-name {
    font-size: 1.3rem;
  }
  
  .product-name-number {
    font-size: 1.3rem;
  }
  
  .product-price {
    font-size: 1.1rem;
  }
  
  .product-image-container {
    height: 17rem;
  }
}
