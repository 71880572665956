/* SearchIcon.css */

.search-icon-container {
    position: relative;
  }

  .search-icon-display {
    cursor: pointer;
  }

  .search-bar-container {
    position: absolute;
    top: 100%;
    right: 0;
    width: 15rem;
    border-radius: 12px;
    padding: 0.7rem;
    z-index: 1000;
  }

  .search-bar-container input {
    width: 15rem;
    padding: 0.7rem;
    border: 0.1rem solid #ccc;
    border-radius: 12px;
    outline: none;
    font-size: 1rem;
  }

  .close-icon {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .search-bar-container {
        width: 8rem;
        border-radius: 8px;
        padding: 0.5rem;
        z-index: 1000;
      }

      .search-bar-container input {
        width: 8rem;
        padding: 0.5rem;
        border: 0.08rem solid #ccc;
        border-radius: 8px;
        outline: none;
        font-size: 0.7rem;
      }

      .close-icon {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        cursor: pointer;
      }
  }
