/* Checkout.css */
.checkout-container {
  display: flex;
  flex-direction: column;
  margin: 2.5rem 3.125rem;
  padding-bottom: 5.625rem;
}

.content-emptycar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65vh;
}

.content-emptycar p {
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}

.content-emptycar button {
  padding: 1.25rem 1.875rem;
  font-size: 0.9375rem;
  color: black;
  border: none;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.content-emptycar button:hover {
  background-color:#CCCCCC;
}

.content-car {
  display: flex;
  width: 100%;
  gap: 1.5rem;
  padding-bottom: 20rem;
}

.cart-items-table {
  width: 100%;
  flex: 4.5;
  max-width: 100%;
  border-collapse: separate;
}

.cart-items-table th {
  text-align: center;
  padding: 0.125rem;
  font-size: 0.9375rem;
  border-bottom: 0.0625rem solid #ddd;
}

.cart-items-table td {
  padding: 0.125rem;
  font-size: 0.9375rem;
  text-align: center;
  border-bottom: 0.0625rem solid #ddd;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
}

.total-price-column {
  position: sticky;
  top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.column-subtotal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.3125rem;
}

.prices-column {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.total-price-column h2 {
  font-size: 0.9375rem;
  margin-bottom: 1.25rem;
}

.total-price-column p {
  margin: 0.3125rem;
  justify-content: flex-end;
}

.checkout-button {
  margin: 1.25rem;
  margin-top: 1.875rem;
  padding: 0.9375rem 1.375rem;
  font-size: 0.875rem;
  color: black;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.checkout-button:hover {
  background-color:#CCCCCC;
}

.quantity-table {
  align-items: center;

}

.button-checkoutpage {
  padding: 0.3125rem 0.625rem;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 0.3125rem;
  cursor: pointer;
  font-size: 0.9375rem;
  margin: 0rem;
}

.button-checkoutpage:not([disabled]):hover {
  background-color:#CCCCCC;
}

.quantity-table span {
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.product-name-checkout:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .content-emptycar p {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .content-emptycar button {
    padding: 1rem 1.3rem;
    font-size: 0.8rem;
  }

  .checkout-container {
    margin: 2rem 1rem;
  }

  .content-car {
    flex-direction: column;
  }
  
  .cart-items-table th {
    padding: 0.1rem;
    font-size: 0.7rem;
    border-bottom: 0.03rem solid #ddd;
  }

  .cart-items-table td {
    padding: 0.05rem;
    font-size: 0.7rem;
    border-bottom: 0.03rem solid #ddd;
    text-align: left;
  }

  .button-checkoutpage {
    padding: 0.1rem 0.3rem;
    cursor: pointer;
    font-size: 0.8rem;
    margin: 0rem;
  }

  .quantity-table span {
    margin: 0rem 0.2rem 0rem 0.2rem;
  }

  .total-price-column h2 {
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
   }

   .total-price-column p {
    font-size: 0.8rem;
  }

  .checkout-button {
    margin-top: 1.5rem;
    padding: 0.7rem 0.9rem;
    font-size: 0.7rem;
  }
}

@media (min-width: 3000px) {
  .checkout-container {
    margin: 5rem 8rem;
  }

  .content-emptycar p {
    font-size: 1.4rem;
  }

  .content-emptycar button {
    font-size: 1.2rem;
  }

  .checkout-container {
    padding-bottom: 40rem;
  }
  
  .cart-items-table th {
    font-size: 1.2rem;
  }
  
  .cart-items-table td {
    font-size: 1.2rem;
  }

  .total-price-column h2 {
    font-size: 1.2rem;
  }
  
  .checkout-button {
    padding: 0.9375rem 1.5rem;
    font-size: 1.1rem;
  }

  .prices-column p{
    font-size: 1.2rem;
  }
  
  .column-subtotal p{
    font-size: 1.5rem;
  }

  .button-checkoutpage {
    font-size: 1.2rem;
  }
}
